<template>
  <div>
  <div class="schArtDetail">
      <Head></Head>

      <div class="info">

          <div class="back">

            <span @click="backSch()">返回</span>

            <em>/</em>
            <span>公告详情</span>
          </div>

          <h1>{{schInfo.title}}</h1>
          <div class="bar">
              <ul>
                  <li>{{schInfo.pubdata | dataFormat}}</li>
                  <li>来源：{{schInfo.author}}</li>
                  <li>浏览:{{schInfo.view}}</li>
              </ul>
          </div>
      </div>
    <!-- <div class="content">
        <p v-html="schInfo.content"></p>
    </div> -->

    <!-- <div class="content">
        <div class="ql-snow">
          <div class="ql-editor">
              <div v-html="schInfo.content"></div>
          </div>
      </div>
    </div> -->

      <div class="ql-snow">
          <div class="ql-editor">
            <div class="content">
              <div v-html="schInfo.content"></div>
          </div>
          </div>
      </div>

    


  </div>
   <Foot></Foot>
  </div>
</template>

<script>
import { getDetailSchArtApi } from '../../API/schArt.js'
import dayjs from 'dayjs'
import head from '../head.vue'
import Foot from '../../views/foot.vue'
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

export default {
  name: 'schArtDetail',
  props: ['schArtId'],
  data () {
    return {
      // 内容高度
      contnetHeight: document.body.scrollHeight,
      // 可视高度
      windowHeight: document.documentElement.clientHeight,
      minHeight: 0,
      schInfo: {}
    }
  },
  methods: {
    getDetail () {
      this.$nextTick(async () => {
        const { data: res } = await getDetailSchArtApi(this.schArtId)
        if (res.status === 0) {
          this.schInfo = res.data
        } else {
          this.$message({
            type: 'error',
            message: res.message
          })
        }
      })
      //   console.log(this.$route.params.schArtId)

      // if (height < 1483) {
      //   document.body.height = 1483 + 'px'
      // }
    },
    backSch () {
      this.$emit('showSchInfo', true)
      this.$router.back(-1)
    }

  },
  created () {
    this.getDetail()
  },
  filters: {
    dataFormat: (day) => {
      return dayjs(day).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  mounted () {
    this.minHeight = document.documentElement.clientHeight - 170
    var that = this
    window.onresize = function () {
      that.minHeight = document.documentElement.clientHeight - 170
    }
  },
  components: {
    Head: head,
    Foot: Foot
  },
  watch: {
    schArtId (newId) {
      // this.schInfo = null
      this.schArtId = newId
      this.getDetail()
    }
  }
}
</script>

<style lang="less" scoped>
    li{
        list-style: none;
    }
    a{
        text-decoration: none;
    }
    .schArtDetail{
      position: relative;
      padding-bottom: 61px;
      min-height: 100vh;
      .info{
        width: 80%;
        margin: .5rem auto;
        .back{
            float: left;
            span{
            display: inline-block;
            text-align: left;
            height: 37px;
            line-height: 37px;
            font-size: 14px;
            cursor: pointer;
            color: #999aaa;
            padding-left: .1333rem;

        }
        span:hover{
              color:#ff3535
            }
        em{
          margin: .0833rem;
        }
        }
        h1{
            width: 70%;
            word-wrap: brea;
            text-align: center;
            padding-bottom:20px;
            font-size: .5rem;
            margin: 0 auto;

        }
        .bar{
            width: 100%;
            border-top: 1px solid  #dcdcdc;
            ul{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 0;
                li{
                    font-size: 15px;
                    padding: 16px 10px 0 0 ;
                    color:#585858;
                }
            }
        }
    }
    .content{
        width: 70%;
        margin: 25px auto 20px;
        font-size: 20px;
        p{
            line-height: 40px;
            text-indent:1em;
        }
    }
    .fixBottom{
      position: fixed;
      bottom: 0;
    }
    }

</style>
